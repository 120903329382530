import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj24 = () => {
  return (
    <Layout>
      <SEO
        title="Speech_to_text-no_need_to_write"
        description="Awesome python project"
      />
      <Particle8></Particle8>
      <h6>s</h6>
      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-24">
          <div className="py-1-heading">
            <h1>Speech_to_text-no_need_to_write</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Speech%20To%20Text-No%20Need%20To%20Write"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              First, I created a audio clip using my microphone.
              <br />
              <br />
              Now the basic idea is to represent everything I said in written
              form.
              <br />
              <br />
              There is no issue, Python is there for our rescue.
              <br />
              <br />
              Speech recognition module is used here, so it can understant the
              audio file and capture <br />
              everything for us.
              <br />
              <br />
              !!!ATTENTION!!! :- Audio file must be in '.wav' extention.
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Speech Recognition</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj24
